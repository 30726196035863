<template>
    <div class="step">
        <h2>Edit Step</h2>
        <form @submit.prevent="handleSubmit">
            <header>
                <span class="title" :class="{null: !data.title}">{{data.title || `title`}}</span>
                <div class="buttons">
                    <span class="preview" @click="handlePreview">Step Preview</span>
                    <button type="submit" class="btn">Save</button>
                </div>
            </header>
            <div class="items">
                <div class="left">
                    <div class="main">
                        <div class="inputs">
                            <template v-for="(input, i) in inputs" :key="i">
                                <Input :data="input" :values="initData" @handleData="handleData" />
                            </template>
                        </div>
                        <Documents :data="initData.documents" @handleData="handleData" />
                    </div>
                    <div class="extras">
                        <Measurements :data="initData.measurement" @handleData="handleData" />
                        <Tools :data="initData.tools" @handleData="handleData" />
                    </div>
                </div>
                <div class="right">
                    <Hotspot :data="{image: initData.images?.[0] || {}, hotspots: initData.hotspots}" @handleData="handleData" />
                </div>
            </div>
        </form>
        <preview
            v-if="seePreview"
            :type="'step'"
            :info="stepInfo"
            @close="seePreview = false"
        />
    </div>
</template>

<script>
import Input from '@/components/Ui/Step/Input.vue'
import Hotspot from '@/components/Ui/Step/Hotspot/Index.vue'
import Documents from '@/components/Ui/Step/Documents.vue'
import Measurements from '@/components/Ui/Step/Measurements.vue'
import Tools from '@/components/Ui/Step/Tools.vue'
import axiosInstance from "@/services/AxiosTokenInstance";
import { mapMutations } from 'vuex'
import Preview from "@/components/previews/Preview.vue";

export default {
    components: {
        Input,
        Hotspot,
        Documents,
        Measurements,
        Tools,
        Preview
    },

    data() {
        return {
            data: {
                title: undefined
            },
            initData: {},
            loaded: true,
            guideId: undefined,
            seePreview: false,
            stepInfo: {}
        }
    },

    computed: {
        inputs() {
            return [
                {
                    type: 'text',
                    name: 'title',
                    label: 'Title',
                    placeholder: 'Title',
                    required: true
                },
                {
                    type: 'textarea',
                    name: 'description',
                    label: 'Description',
                    placeholder: 'Description',
                    required: true
                }
            ]
        }
    },

    created() {
        this.getStepDetails()
    },

    methods: {
        ...mapMutations({
            pathing: "pathing/setPathing",
        }),

        async getStepDetails(value) {
            this.loaded = false
            this.stepId = this.$route.params.stepid;
            this.currentPage = value;
            this.stepId = this.$route.params.stepid;
            let response = await axiosInstance.get(`step/${this.stepId}`).finally(() => this.loaded = true);
            this.initData = response.data.data
            this.guideId = this.initData.id_troubleshoot

            this.pathing([
            { name: "categories", link: "/products/guides" },
            {
                name: `Guides for ${this.initData.troubleshoot.category.name}`,
                link: `/products/guides/${this.initData.troubleshoot.category.id}`,
            },
            {
                name: this.initData.troubleshoot.title,
                link: `/products/guides/${this.initData.troubleshoot.category.id}/step-list/${this.initData.troubleshoot.id}`,
            },
            { name: this.initData.title },
            ]);
        },

        handleData(item) {
            this.data[item.name] = item.value
        },

        handlePreview() {
            this.stepInfo = {
                type: "step",
                title: this.data.title,
                description: this.data.description,
                extras: {
                    image: {
                        info: [this.data.image_file],
                        hotspots: this.data.hotspots,
                    },
                    files: this.data.documents || [],
                    measurements: this.data.measurements || [],
                },
            };
            
            this.seePreview = true;
        },

        async handleSubmit() {
            this.loaded = false
            
            let finalImage = [];
            if (this.data.image_file) {
                if (this.data.image_file.link.includes('http')) {
                    finalImage = [
                        {
                            id: this.initData.images[0].id,
                            image_file: {},
                            hotspots: this.data.hotspots?.map(el => {
                                return {
                                    number: el.number || el.sequence,
                                    position: el.position,
                                    description: el.description,
                                    color: el.color,
                                }
                            }) || [],
                        }
                    ];
                }
                else {
                    finalImage = [
                        {
                            image_file: {
                                mime: this.data.image_file.og_file.type || 'image/jpeg',
                                data: this.data.image_file.link.split(',')[1],
                                size: this.data.image_file.og_file.size?.toString() || '0'
                            },
                            hotspots: this.data.hotspots || [],
                        }
                    ];
                }
                
            }

            const finalDocs = this.data?.newDocuments?.map(el => {
                return {
                    mime: el.og_doc.type,
                    data: el.link.split(',')[1],
                    size: el.size,
                    pages: el.pages?.toString() || 0,
                }
            }) || [];

            const deletedDocs = this.data?.deletedDocuments?.map(el => el.id)

            const org = JSON.parse(localStorage.getItem("organization"));

            let bodyFormData = {
                step_id: Number(this.$route.params.stepid),
                id_organization: org.id,
                title: this.data.title,
                name: this.data.title,
                description: this.data.description,
                image: finalImage,
                documents: finalDocs,
                deleted_documents: deletedDocs,
                measurements: this.data.measurements?.map(el => {
                    return {
                        name: el.name,
                        notes: (!el.notes || el.notes === 2) ? 2 : 1,
                        scale: el.scale,
                        value: el.value,
                        variable: el.variable,
                        is_mandatory: (!el.is_mandatory || el.is_mandatory === 2) ? 2 : 1
                    }
                }) || [],
                create_tools: this.data.newTools?.length ? this.data.newTools.map(el => {
                    const toReturn = {
                        name: el.name
                    }

                    if (el.image) {
                        toReturn.icon = el.image.link
                    }

                    return toReturn
                })  : [],
                delete_tools: this.data.deletedTools?.map(el => el.id) || [],
                status: 1
            };

            let response = await axiosInstance.post("update-troubleshoot-step", bodyFormData).finally(() => this.loaded = true);
            if (response.status == 200) {
                this.$router.push({
                     path: `/products/guides/${this.$route.params.catid}/step-list/${this.guideId}`,
                });
                this.emitter.emit('alert', 'Step created successfuly')
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .step {
        display: flex;
        flex-direction: column;

        h2 {
            font-weight: bold;
            font-size: 1.3rem;
        }

        form {
            display: flex;
            flex-direction: column;
            background: white;
            width: 100%;
            height: calc(100vh - 250px);
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            box-shadow: 0px 1px 8px #142e6e1a;
            overflow: hidden;

            header {
                width: 100%;
                height: 4rem;
                box-shadow: 0px 1px 8px #142e6e1a;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;

                .title {
                    font-size: 1.2rem;
                    font-weight: 600;

                    &.null {
                        opacity: 0.5;
                        text-transform: capitalize;
                    }
                }
                
                .buttons {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    button {
                        font-size: 1rem;
                        font-weight: 500;
                    }
                    
                    .preview {
                        cursor: pointer;
                        user-select: none;
                        background: none;
                        text-decoration: underline;
                        transition: 0.2s;

                        &:hover {
                            color: $grey;
                        }
                    }
                }
            }

            .items {
                padding: 20px;
                display: flex;
                gap: 20px;
                overflow: auto;
                
                .left {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    width: 50%;

                    .main {
                        width: 100%;
                        display: flex;
                        gap: 20px;

                        .inputs {
                            width: 50%;
                            height: 15rem;
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;
                        }
                    }

                    .extras {
                        width: 100%;
                        display: flex;
                        gap: 20px;
                    }
                }
                .right {
                    width: 50%;
                    height: 100%;
                }
            }
        }
    }
</style>